
import linkify from 'linkifyjs/html';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teamInfo: {} as {
      description: string;
      created: string;
    },
  }),
  created() {
    this.loadTeamInfo();
  },
  methods: {
    descriptionHtml() {
      return linkify(
        this.linebreaksbr(this.teamInfo.description),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadTeamInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/info/`,
      });

      if (responseData.status === 200) {
        this.teamInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
