<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the team info. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadTeamInfo"
    >
      Try Again
    </button>
  </template>

  <div
    v-else-if="status === 'loaded'"
    class="card"
  >
    <div
      class="description"
      v-html="descriptionHtml()"
    />

    This team was created on {{ timestampDisplay(teamInfo.created) }}
  </div>
</template>

<script lang="ts">
import linkify from 'linkifyjs/html';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teamInfo: {} as {
      description: string;
      created: string;
    },
  }),
  created() {
    this.loadTeamInfo();
  },
  methods: {
    descriptionHtml() {
      return linkify(
        this.linebreaksbr(this.teamInfo.description),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadTeamInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/info/`,
      });

      if (responseData.status === 200) {
        this.teamInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .description {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray);
  }
</style>
