<template>
  <template v-if="teamStatus === 'loaded'">
    <img
      v-if="team.cover_image"
      :src="team.cover_image"
      alt
      class="img-fluid cover-image"
    >

    <div class="horizontal-padding">
      <div class="below-cover-image">
        <div>
          <h1>
            {{ team.name }}
          </h1>

          <p>
            <template v-if="team.visibility === 'public'">
              Public
            </template>

            <template v-else>
              Private
            </template>

            Team • Plays

            <router-link :to="{ name: 'game', params: { gameSlug: team.game_slug } }">
              {{ team.game_name_and_year }}
            </router-link>

            on

            {{ toListSentence(team.platforms) }}
          </p>
        </div>

        <div
          v-if="loggedIn"
          class="buttons"
        >
          <template
            v-if="
              ['regular', 'moderator', 'admin'].includes(team.user_role_and_access.role)
            "
          >
            <invite-to-team :team-slug="teamSlug" />

            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              :disabled="leavingTeam"
              @click="leaveTeam"
            >
              Leave Team
            </button>
          </template>

          <button
            v-else-if="team.user_role_and_access.role === 'needs_team_approval'"
            v-tooltip="'Your request to join this team is pending approval'"
            type="button"
            class="btn btn-outline-light btn-sm"
            :disabled="cancelingJoinRequest"
            @click="cancelJoinRequest"
          >
            Cancel Join Request
          </button>

          <div
            v-else
            v-tooltip="joinTeamTooltip"
            tabindex="0"
          >
            <button
              :aria-label="joinTeamTooltip"
              type="button"
              class="btn btn-outline-primary btn-sm"
              :disabled="joinTeamButtonDisabled"
              @click="joinTeam"
            >
              Join Team
            </button>
          </div>
        </div>
      </div>

      <nav
        v-if="['read', 'write'].includes(team.user_role_and_access.access)"
        class="navbar"
        aria-label="Team"
      >
        <ul>
          <li>
            <router-link :to="{ name: 'team_info', params: { teamSlug } }">
              Info
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'team', params: { teamSlug } }">
              Posts
            </router-link>
          </li>
          <template
            v-if="['regular', 'moderator', 'admin'].includes(team.user_role_and_access.role)"
          >
            <li>
              <router-link :to="{ name: 'team_calendar', params: { teamSlug } }">
                Calendar
              </router-link>
            </li>
            <li>
              <router-link
                v-slot="{ href, navigate }"
                :to="{ name: 'team_members', params: { teamSlug } }"
                custom
              >
                <li>
                  <a
                    :href="href"
                    :class="{ 'router-link-exact-active': activeTab.startsWith('members') }"
                    @click="navigate"
                  >Members</a>
                </li>
              </router-link>
            </li>
          </template>
          <li v-if="team.user_role_and_access.role === 'admin'">
            <router-link
              v-slot="{ href, navigate }"
              :to="{ name: 'team_settings', params: { teamSlug } }"
              custom
            >
              <li>
                <a
                  :href="href"
                  :class="{ 'router-link-exact-active': activeTab.startsWith('settings') }"
                  @click="navigate"
                >Settings</a>
              </li>
            </router-link>
          </li>
        </ul>
      </nav>

      <div :class="`content ${activeTab}-content`">
        <team-info
          v-if="activeTab === 'info'"
          :team-slug="teamSlug"
        />

        <template v-if="['read', 'write'].includes(team.user_role_and_access.access)">
          <post-list
            v-if="activeTab === 'posts'"
            :team="team"
          />

          <template v-else-if="['post', 'comment'].includes(activeTab)">
            <spinner
              v-if="postStatus === 'loading'"
              preset="large"
            />

            <template v-else-if="postStatus === 'loaded'">
              <alert
                v-if="showCommentNotFoundMessage"
                variant="danger"
                dismissible
                @dismissed="showCommentNotFoundMessage = false"
              >
                That comment does not exist (it might have been deleted).
              </alert>

              <post
                ref="post"
                :post="post"
                :team-slug="team.slug !== undefined ? team.slug : ''"
                :team-user-role-and-access="team.user_role_and_access"
                @postDeleted="$router.push({ name: 'team', params: { teamSlug } })"
              />
            </template>

            <template v-else-if="postStatus === 'error'">
              <alert variant="danger">
                An error occurred loading the post. Please check your connection
                and try again.
              </alert>

              <button
                type="button"
                class="btn btn-outline-primary"
                @click="loadPost"
              >
                Try Again
              </button>
            </template>

            <alert
              v-else-if="postStatus === 'not_found'"
              variant="danger"
            >
              That post does not exist.
            </alert>
          </template>

          <team-calendar
            v-if="activeTab === 'calendar'"
            :team-slugs="[teamSlug]"
          />

          <team-members
            v-else-if="activeTab.startsWith('members')"
            :key="`${activeTab}${teamMembersKeySuffix}`"
            :active-tab="activeTab"
            :team-slug="teamSlug"
            :team-user-role-and-access="team.user_role_and_access"
            @roleChanged="teamMembersKeySuffix += 1"
          />

          <team-settings
            v-else-if="activeTab.startsWith('settings')"
            :key="activeTab"
            :active-tab="activeTab"
            :team-slug="teamSlug"
            @basicSettingsSaved="basicSettingsSaved"
            @coverImageSaved="coverImageSaved"
          />
        </template>
      </div>
    </div>
  </template>

  <div
    v-else
    class="horizontal-padding"
  >
    <spinner
      v-if="teamStatus === 'loading'"
      preset="large"
    />

    <template v-else-if="teamStatus === 'error'">
      <alert variant="danger">
        An error occurred loading the team. Please check your connection and
        try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadTeam"
      >
        Try Again
      </button>
    </template>

    <alert
      v-else-if="teamStatus === 'not_found'"
      variant="danger"
    >
      That team does not exist.
    </alert>
  </div>
</template>

<script lang="ts">
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent } from 'vue';
import Post from '@/components/posts/Post.vue';
import PostList from '@/components/posts/PostList.vue';
import InviteToTeam from '@/components/teams/InviteToTeam.vue';
import TeamCalendar from '@/components/teams/TeamCalendar.vue';
import TeamInfo from '@/components/teams/TeamInfo.vue';
import TeamMembers from '@/components/teams/TeamMembers.vue';
import TeamSettings from '@/components/teams/TeamSettings.vue';
import { CommentInterface } from '@/interfaces/posts';
import { TeamDetailInterface } from '@/interfaces/teams';

export default defineComponent({
  components: {
    Post,
    PostList,
    InviteToTeam,
    TeamCalendar,
    TeamInfo,
    TeamMembers,
    TeamSettings,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadTeam();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadTeam();
    }, 0);
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    postId: {
      type: Number,
      required: false,
      default: 0,
    },
    commentId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    teamStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    team: {} as TeamDetailInterface,
    postStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    post: {},
    showCommentNotFoundMessage: false,
    joiningTeam: false,
    cancelingJoinRequest: false,
    leavingTeam: false,
    teamMembersKeySuffix: 0,
  }),
  computed: {
    joinTeamButtonDisabled() {
      if (this.joiningTeam) {
        return true;
      }

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        return false;
      }

      if (
        !this.team.invite_only
        && ['none', 'denied_by_user'].includes(this.team.user_role_and_access.role)
      ) {
        return false;
      }

      return true;
    },
    joinTeamTooltip() {
      if (['none', 'denied_by_user'].includes(this.team.user_role_and_access.role)) {
        if (this.team.invite_only) {
          return 'This team is invite only';
        }

        return 'Request to join this team';
      }

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        return 'Accept the invitation to join this team';
      }

      return 'Your request to join this team was denied';
    },
  },
  methods: {
    basicSettingsSaved(newTeamData: TeamDetailInterface) {
      if (this.team.slug === newTeamData.slug) {
        this.team = newTeamData;
      } else {
        this.$router.push({ name: 'team_settings', params: { teamSlug: newTeamData.slug } });
      }
    },
    async cancelJoinRequest() {
      this.cancelingJoinRequest = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/join/`,
        method: 'POST',
        json: { action: 'cancel' },
      });

      this.cancelingJoinRequest = false;

      if (responseData.status === 200) {
        this.team.user_role_and_access = responseData.body;
      } else {
        let text;

        if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal('Failed to Cancel the Request to Join', text);
      }
    },
    coverImageSaved(newCoverImage: null | string) {
      this.team.cover_image = newCoverImage;
    },
    async loadPost() {
      this.postStatus = 'loading';

      const responseData = await this.api({
        url: `posts/${this.postId}/?team=${this.teamSlug}`,
      });

      if (responseData.status === 200) {
        const post = responseData.body;

        if (post.comments.length) {
          let previousPath = '';
          let index = 0;
          post.comments.forEach((comment: CommentInterface) => {
            const path = `${comment.path.join('-')}-`;

            if (index) {
              post.comments[index - 1].has_children = path.startsWith(previousPath);
            }

            previousPath = path;
            index += 1;
          });

          post.comments[index - 1].has_children = false;
        }

        this.post = post;
        this.postStatus = 'loaded';

        if (this.commentId) {
          const comment = post.comments.filter((c: CommentInterface) => c.id === this.commentId)[0];

          if (comment) {
            this.$nextTick(() => {
              (this.$refs.post as InstanceType<typeof Post>).navigateToCommentById(this.commentId);
            });
          } else {
            this.showCommentNotFoundMessage = true;
          }
        }
      } else if (responseData.status === 404) {
        this.postStatus = 'not_found';
      } else {
        this.postStatus = 'error';
      }
    },
    async loadTeam() {
      if (this.teamStatus === 'loaded' && this.team.slug === this.teamSlug) {
        this.afterTeamLoaded();
        return;
      }

      this.teamStatus = 'loading';

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/`,
      });

      if (responseData.status === 200) {
        this.team = responseData.body;
        this.teamStatus = 'loaded';
        this.afterTeamLoaded();
      } else if (responseData.status === 404) {
        this.teamStatus = 'not_found';
      } else {
        this.teamStatus = 'error';
      }
    },
    afterTeamLoaded() {
      if (
        this.activeTab !== 'info'
        && this.team.user_role_and_access.access === 'info'
      ) {
        this.$router.replace({ name: 'team_info', params: { teamSlug: this.teamSlug } });
      } else if (['post', 'comment'].includes(this.activeTab)) {
        this.loadPost();
      }
    },
    async joinTeam() {
      this.joiningTeam = true;

      let action;

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        action = 'accept';
      } else {
        action = 'request';
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/join/`,
        method: 'POST',
        json: { action },
      });

      this.joiningTeam = false;

      if (responseData.status === 200) {
        this.team.user_role_and_access = responseData.body;
      } else {
        let title;
        let text;

        if (action === 'accept') {
          title = 'Failed to Accept the Invitation';
        } else {
          title = 'Failed to Request to Join';
        }

        if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
    async leaveTeam() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to leave this team?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Leave This Team',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.leavingTeam = true;

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/leave/`,
            method: 'POST',
          });

          this.leavingTeam = false;

          if (responseData.status === 200) {
            this.team.user_role_and_access = responseData.body;

            if (this.team.user_role_and_access.access === 'none') {
              this.$router.push({ name: 'feed' });
            } else if (this.team.user_role_and_access.access === 'info') {
              this.$router.push({ name: 'team_info', params: { teamSlug: this.teamSlug } });
            }
          } else {
            let text;

            if (responseData.status === 400) {
              [text] = responseData.body.non_field_errors;
            } else if (responseData.status === 404) {
              text = 'That team does not exist.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal('Failed to Leave the Team', text);
          }
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  .cover-image {
    display: block;

    + div .below-cover-image {
      padding-top: 1rem;
    }
  }

  .below-cover-image {
    margin-bottom: 1rem;
    padding-top: 0.2rem;
    word-break: break-word;

    h1 {
      margin-bottom: 0.5rem;
      font-size: 1.4rem;
      text-align: left;
    }
  }

  .buttons > :deep(.btn) {
    white-space: nowrap;
  }

  .profile-image {
    align-self: start;
  }

  .content {
    margin: 0 auto;
    max-width: 500px;
  }

  .calendar-content {
    max-width: 1000px;
  }

  @media (min-width: 667px) {
    .below-cover-image {
      display: flex;
      align-items: flex-start;
    }

    .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 1rem;

      div {
        margin-left: 0.5rem;
      }
    }
  }
</style>
