
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent } from 'vue';
import Post from '@/components/posts/Post.vue';
import PostList from '@/components/posts/PostList.vue';
import InviteToTeam from '@/components/teams/InviteToTeam.vue';
import TeamCalendar from '@/components/teams/TeamCalendar.vue';
import TeamInfo from '@/components/teams/TeamInfo.vue';
import TeamMembers from '@/components/teams/TeamMembers.vue';
import TeamSettings from '@/components/teams/TeamSettings.vue';
import { CommentInterface } from '@/interfaces/posts';
import { TeamDetailInterface } from '@/interfaces/teams';

export default defineComponent({
  components: {
    Post,
    PostList,
    InviteToTeam,
    TeamCalendar,
    TeamInfo,
    TeamMembers,
    TeamSettings,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadTeam();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadTeam();
    }, 0);
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    postId: {
      type: Number,
      required: false,
      default: 0,
    },
    commentId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    teamStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    team: {} as TeamDetailInterface,
    postStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    post: {},
    showCommentNotFoundMessage: false,
    joiningTeam: false,
    cancelingJoinRequest: false,
    leavingTeam: false,
    teamMembersKeySuffix: 0,
  }),
  computed: {
    joinTeamButtonDisabled() {
      if (this.joiningTeam) {
        return true;
      }

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        return false;
      }

      if (
        !this.team.invite_only
        && ['none', 'denied_by_user'].includes(this.team.user_role_and_access.role)
      ) {
        return false;
      }

      return true;
    },
    joinTeamTooltip() {
      if (['none', 'denied_by_user'].includes(this.team.user_role_and_access.role)) {
        if (this.team.invite_only) {
          return 'This team is invite only';
        }

        return 'Request to join this team';
      }

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        return 'Accept the invitation to join this team';
      }

      return 'Your request to join this team was denied';
    },
  },
  methods: {
    basicSettingsSaved(newTeamData: TeamDetailInterface) {
      if (this.team.slug === newTeamData.slug) {
        this.team = newTeamData;
      } else {
        this.$router.push({ name: 'team_settings', params: { teamSlug: newTeamData.slug } });
      }
    },
    async cancelJoinRequest() {
      this.cancelingJoinRequest = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/join/`,
        method: 'POST',
        json: { action: 'cancel' },
      });

      this.cancelingJoinRequest = false;

      if (responseData.status === 200) {
        this.team.user_role_and_access = responseData.body;
      } else {
        let text;

        if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal('Failed to Cancel the Request to Join', text);
      }
    },
    coverImageSaved(newCoverImage: null | string) {
      this.team.cover_image = newCoverImage;
    },
    async loadPost() {
      this.postStatus = 'loading';

      const responseData = await this.api({
        url: `posts/${this.postId}/?team=${this.teamSlug}`,
      });

      if (responseData.status === 200) {
        const post = responseData.body;

        if (post.comments.length) {
          let previousPath = '';
          let index = 0;
          post.comments.forEach((comment: CommentInterface) => {
            const path = `${comment.path.join('-')}-`;

            if (index) {
              post.comments[index - 1].has_children = path.startsWith(previousPath);
            }

            previousPath = path;
            index += 1;
          });

          post.comments[index - 1].has_children = false;
        }

        this.post = post;
        this.postStatus = 'loaded';

        if (this.commentId) {
          const comment = post.comments.filter((c: CommentInterface) => c.id === this.commentId)[0];

          if (comment) {
            this.$nextTick(() => {
              (this.$refs.post as InstanceType<typeof Post>).navigateToCommentById(this.commentId);
            });
          } else {
            this.showCommentNotFoundMessage = true;
          }
        }
      } else if (responseData.status === 404) {
        this.postStatus = 'not_found';
      } else {
        this.postStatus = 'error';
      }
    },
    async loadTeam() {
      if (this.teamStatus === 'loaded' && this.team.slug === this.teamSlug) {
        this.afterTeamLoaded();
        return;
      }

      this.teamStatus = 'loading';

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/`,
      });

      if (responseData.status === 200) {
        this.team = responseData.body;
        this.teamStatus = 'loaded';
        this.afterTeamLoaded();
      } else if (responseData.status === 404) {
        this.teamStatus = 'not_found';
      } else {
        this.teamStatus = 'error';
      }
    },
    afterTeamLoaded() {
      if (
        this.activeTab !== 'info'
        && this.team.user_role_and_access.access === 'info'
      ) {
        this.$router.replace({ name: 'team_info', params: { teamSlug: this.teamSlug } });
      } else if (['post', 'comment'].includes(this.activeTab)) {
        this.loadPost();
      }
    },
    async joinTeam() {
      this.joiningTeam = true;

      let action;

      if (this.team.user_role_and_access.role === 'needs_user_approval') {
        action = 'accept';
      } else {
        action = 'request';
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/join/`,
        method: 'POST',
        json: { action },
      });

      this.joiningTeam = false;

      if (responseData.status === 200) {
        this.team.user_role_and_access = responseData.body;
      } else {
        let title;
        let text;

        if (action === 'accept') {
          title = 'Failed to Accept the Invitation';
        } else {
          title = 'Failed to Request to Join';
        }

        if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
    async leaveTeam() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to leave this team?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Leave This Team',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.leavingTeam = true;

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/leave/`,
            method: 'POST',
          });

          this.leavingTeam = false;

          if (responseData.status === 200) {
            this.team.user_role_and_access = responseData.body;

            if (this.team.user_role_and_access.access === 'none') {
              this.$router.push({ name: 'feed' });
            } else if (this.team.user_role_and_access.access === 'info') {
              this.$router.push({ name: 'team_info', params: { teamSlug: this.teamSlug } });
            }
          } else {
            let text;

            if (responseData.status === 400) {
              [text] = responseData.body.non_field_errors;
            } else if (responseData.status === 404) {
              text = 'That team does not exist.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal('Failed to Leave the Team', text);
          }
        }
      });
    },
  },
});
