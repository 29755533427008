<template>
  <modal
    ref="usersModal"
    :show="usersModalShowing"
    large
    @modalClosed="usersModalShowing = false"
  >
    <template #header>
      <h2>Invite Others to the Team</h2>
    </template>

    <div class="invite-container subtle-scrollbar">
      <div class="search subtle-scrollbar">
        <div class="horizontal-padding">
          <div class="form-group">
            <label for="search_keywords">Search users by name, Xbox gamertag, etc.</label>
            <input
              id="search_keywords"
              v-model="searchKeywords"
              type="text"
              @input="searchFieldInput"
            >
          </div>
        </div>

        <template v-if="userListStatus === 'loaded'">
          <template v-if="userList.results.length">
            <ul class="results">
              <li
                v-for="result of userList.results"
                :key="`result${result.username}`"
                :class="{ 'result-selected': selectedUsernames.includes(result.username) }"
              >
                <button
                  type="button"
                  class="btn-transparent horizontal-padding"
                  :disabled="
                    formSubmitting
                      || result.is_team_member
                      || (
                        selectedUsernames.length >= maxSelectedUsers
                        && !selectedUsernames.includes(result.username)
                      )
                  "
                  @click="toggleSelection(result)"
                >
                  <profile-image :user="result" />

                  <div>
                    <div
                      v-if="result.is_team_member"
                      class="already-member"
                    >
                      Already a Member
                    </div>

                    <div>{{ result.first_name }} {{ result.last_name }}</div>

                    <ul v-if="result.search_matches.length">
                      <li
                        v-for="(match, index) of result.search_matches"
                        :key="`match${index}`"
                        class="match"
                      >
                        <strong>{{ match[0] }}</strong>:
                        <span v-html="match[1]" />
                      </li>
                    </ul>
                  </div>
                </button>
              </li>
            </ul>

            <button
              v-show="userListMoreStatus === 'idle' && userList.next"
              type="button"
              class="btn btn-outline-primary load-more-users-button"
              @click="loadUserListMore"
            >
              Load More Users
            </button>

            <spinner
              v-if="userListMoreStatus === 'loading'"
              preset="large"
            />

            <template v-else-if="userListMoreStatus === 'error'">
              <alert variant="danger">
                An error occurred while trying to load more users. Please check
                your connection and try again.
              </alert>

              <button
                type="button"
                class="btn btn-outline-primary"
                @click="loadUserListMore"
              >
                Try Again
              </button>
            </template>
          </template>

          <div
            v-else
            class="horizontal-padding"
          >
            No results
          </div>
        </template>

        <div
          v-else
          class="horizontal-padding"
        >
          <spinner
            v-if="userListStatus === 'loading'"
            preset="large"
          />

          <template v-else-if="userListStatus === 'error'">
            <alert variant="danger">
              An error occurred loading the search results. Please check your
              connection and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadUserList"
            >
              Try Again
            </button>
          </template>
        </div>
      </div>

      <div class="selected-users subtle-scrollbar horizontal-padding">
        <p>{{ pluralize(selectedUsers.length, 'user') }} selected</p>

        <ul>
          <li
            v-for="selectedUser of selectedUsers"
            :key="`selectedUser${selectedUser.username}`"
          >
            <profile-image :user="selectedUser" />

            <div>
              <div>{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
            </div>

            <button
              type="button"
              class="btn-transparent"
              :disabled="formSubmitting"
              @click="toggleSelection(selectedUser)"
            >
              <x-icon />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <template #footer>
      <div class="modal-footer">
        <p v-if="selectedUsernames.length >= maxSelectedUsers">
          You have selected the maximum number of users.
        </p>

        <button
          type="button"
          class="btn btn-primary"
          :disabled="selectedUsers.length === 0 || formSubmitting"
          @click="submitForm"
        >
          <template v-if="formSubmitting">
            Inviting {{ pluralize(selectedUsers.length, 'User') }}
            <spinner />
          </template>

          <template v-else>
            Invite

            <template v-if="selectedUsers.length">
              {{ pluralize(selectedUsers.length, 'User') }}
            </template>

            <template v-else>
              Users
            </template>
          </template>
        </button>
      </div>
    </template>
  </modal>

  <button
    type="button"
    class="btn btn-outline-primary btn-sm"
    @click="showUsersModal"
  >
    <user-plus-icon />
    Invite
  </button>
</template>

<script lang="ts">
import debounce from 'debounce';
import { defineComponent } from 'vue';
import { UserPlusIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserDirectoryResultWithIsTeamMemberInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    UserPlusIcon,
    XIcon,
    Modal,
    ProfileImage,
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    usersModalShowing: false,
    searchKeywords: '',
    userListStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    userList: {} as {
      next: string | null,
      previous: string | null,
      results: Array<UserDirectoryResultWithIsTeamMemberInterface>,
    },
    userListMoreStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    selectedUsers: [] as Array<UserDirectoryResultWithIsTeamMemberInterface>,
    selectedUsernames: [] as Array<string>,
    maxSelectedUsers: 10,
    formSubmitting: false,
  }),
  methods: {
    showUsersModal() {
      this.usersModalShowing = true;
      this.loadUserList();
    },
    async loadUserList() {
      if (this.userListStatus === 'loading') {
        return;
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        return;
      }

      this.userListStatus = 'loading';

      const responseData = await this.api({ url: `teams/${this.teamSlug}/invite/?q=${searchKeywords}` });

      if (responseData.status === 200) {
        this.userList = responseData.body;
        this.userListStatus = 'loaded';
      } else {
        this.userListStatus = 'error';
      }
    },
    async loadUserListMore() {
      this.userListMoreStatus = 'loading';

      const responseData = await this.api({
        url: this.userList.next as string,
      });

      if (responseData.status === 200) {
        // this.userList.count = responseData.body.count;
        this.userList.next = responseData.body.next;
        this.userList.previous = responseData.body.previous;
        this.userList.results = this.userList.results.concat(
          responseData.body.results,
        );
        this.userListMoreStatus = 'idle';
      } else {
        this.userListMoreStatus = 'error';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadUserList();
    }, 300),
    async submitForm() {
      this.formSubmitting = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/invite/`,
        method: 'POST',
        json: { usernames: this.selectedUsernames },
      });

      this.formSubmitting = false;
      const usersModal = this.$refs.usersModal as InstanceType<typeof Modal>;

      if (responseData.status === 200) {
        usersModal.closeModal();

        this.searchKeywords = '';
        this.userListStatus = 'idle';
        this.userListMoreStatus = 'idle';
        this.selectedUsers = [];
        this.selectedUsernames = [];

        let title = 'Invitation';

        if (this.selectedUsernames.length > 1) {
          title += 's';
        }

        title += ' Sent!';

        this.$swal(title);
      } else {
        let title = 'Failed to Send Invitation';

        if (this.selectedUsernames.length > 1) {
          title += 's';
        }

        let text;

        if (responseData.status === 400) {
          text = responseData.body;
        } else if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal.fire({ title, text, target: usersModal.$el });
      }
    },
    toggleSelection(result: UserDirectoryResultWithIsTeamMemberInterface) {
      if (this.selectedUsernames.includes(result.username)) {
        this.selectedUsernames = this.selectedUsernames.filter(
          (username) => username !== result.username,
        );

        this.selectedUsers = this.selectedUsers.filter((r) => r.username !== result.username);
      } else {
        this.selectedUsernames.push(result.username);
        this.selectedUsers.push(result);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  :deep(.modal-body) {
    padding-bottom: 0;
  }

  .invite-container {
    height: 100%;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .search,
  .selected-users {
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .results,
  .selected-users ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  .results button,
  .selected-users li {
    display: grid;
    grid-gap: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-wrap: break-word;
  }

  .results {
    li:first-child button {
      border-top: 1px solid var(--gray-darkest);
    }

    button {
      grid-template-columns: auto minmax(0, 1fr);
      width: 100%;
      text-align: left;
      border-bottom: 1px solid var(--gray-darkest);
      transition: background-color 0.15s ease-in-out;

      @supports #{'\selector(*:focus-visible)'}  {
        &:focus {
          outline: 0;
        }

        &:focus-visible {
          background-color: var(--gray-dark);
        }
      }

      &:hover {
        background-color: var(--gray-dark);
      }
    }
  }

  .result-selected button {
    background-color: var(--blue-dark);

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        background-color: var(--blue);
      }
    }

    &:hover {
      background-color: var(--blue);
    }
  }

  .already-member {
    display: inline-block;
    padding: 0.2em;
    font-size: 0.7em;
    color: var(--gray-light);
    border-top: 1px solid var(--gray-light);
    border-bottom: 1px solid var(--gray-light);
    text-transform: uppercase;
    vertical-align: top;
  }

  .match :deep(b) {
    background-color: var(--green);
  }

  .load-more-users-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
  }

  .selected-users {
    li {
      grid-template-columns: auto minmax(0, 1fr) auto;
    }

    button {
      padding: 0.5rem;
      background-color: var(--gray-dark);
      border: 1px solid transparent;
      border-radius: 50%;
      line-height: 1;
      align-self: center;
      transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        outline: 0;
      }

      &:focus-visible {
        border-color: var(--blue);
        box-shadow: 0 0 0 0.2rem var(--blue-a50);
      }

      &:hover {
        background-color: var(--gray);
      }
    }
  }

  @media (max-width: 666.98px) {
    .selected-users {
      padding-top: 1rem;
    }
  }

  @media (min-width: 667px) {
    .invite-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
</style>
