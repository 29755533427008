
import debounce from 'debounce';
import { defineComponent } from 'vue';
import { UserPlusIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserDirectoryResultWithIsTeamMemberInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    UserPlusIcon,
    XIcon,
    Modal,
    ProfileImage,
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    usersModalShowing: false,
    searchKeywords: '',
    userListStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    userList: {} as {
      next: string | null,
      previous: string | null,
      results: Array<UserDirectoryResultWithIsTeamMemberInterface>,
    },
    userListMoreStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    selectedUsers: [] as Array<UserDirectoryResultWithIsTeamMemberInterface>,
    selectedUsernames: [] as Array<string>,
    maxSelectedUsers: 10,
    formSubmitting: false,
  }),
  methods: {
    showUsersModal() {
      this.usersModalShowing = true;
      this.loadUserList();
    },
    async loadUserList() {
      if (this.userListStatus === 'loading') {
        return;
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        return;
      }

      this.userListStatus = 'loading';

      const responseData = await this.api({ url: `teams/${this.teamSlug}/invite/?q=${searchKeywords}` });

      if (responseData.status === 200) {
        this.userList = responseData.body;
        this.userListStatus = 'loaded';
      } else {
        this.userListStatus = 'error';
      }
    },
    async loadUserListMore() {
      this.userListMoreStatus = 'loading';

      const responseData = await this.api({
        url: this.userList.next as string,
      });

      if (responseData.status === 200) {
        // this.userList.count = responseData.body.count;
        this.userList.next = responseData.body.next;
        this.userList.previous = responseData.body.previous;
        this.userList.results = this.userList.results.concat(
          responseData.body.results,
        );
        this.userListMoreStatus = 'idle';
      } else {
        this.userListMoreStatus = 'error';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadUserList();
    }, 300),
    async submitForm() {
      this.formSubmitting = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/invite/`,
        method: 'POST',
        json: { usernames: this.selectedUsernames },
      });

      this.formSubmitting = false;
      const usersModal = this.$refs.usersModal as InstanceType<typeof Modal>;

      if (responseData.status === 200) {
        usersModal.closeModal();

        this.searchKeywords = '';
        this.userListStatus = 'idle';
        this.userListMoreStatus = 'idle';
        this.selectedUsers = [];
        this.selectedUsernames = [];

        let title = 'Invitation';

        if (this.selectedUsernames.length > 1) {
          title += 's';
        }

        title += ' Sent!';

        this.$swal(title);
      } else {
        let title = 'Failed to Send Invitation';

        if (this.selectedUsernames.length > 1) {
          title += 's';
        }

        let text;

        if (responseData.status === 400) {
          text = responseData.body;
        } else if (responseData.status === 404) {
          text = 'That team does not exist.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal.fire({ title, text, target: usersModal.$el });
      }
    },
    toggleSelection(result: UserDirectoryResultWithIsTeamMemberInterface) {
      if (this.selectedUsernames.includes(result.username)) {
        this.selectedUsernames = this.selectedUsernames.filter(
          (username) => username !== result.username,
        );

        this.selectedUsers = this.selectedUsers.filter((r) => r.username !== result.username);
      } else {
        this.selectedUsernames.push(result.username);
        this.selectedUsers.push(result);
      }
    },
  },
});
