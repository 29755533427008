<template>
  <nav aria-label="Team Members">
    <ul>
      <li>
        <router-link
          :to="{ name: 'team_members', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Current Members
        </router-link>
      </li>
      <li v-if="teamUserRoleAndAccess.role !== 'regular'">
        <router-link
          :to="{ name: 'team_join_requests', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Join Requests
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'team_invitations_sent', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Invitations Sent
        </router-link>
      </li>
    </ul>
  </nav>

  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the team members. Please check your connection
      and try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadTeamMembers"
    >
      Try Again
    </button>
  </template>

  <div
    v-else-if="status === 'loaded'"
    class="card"
  >
    <template v-if="teamMembers.results.length">
      <div
        v-for="teamMember of teamMembers.results"
        :key="`teamMember${teamMember.user.username}`"
        class="team-member"
      >
        <div>
          <router-link
            :to="{ name: 'user_profile', params: { username: teamMember.user.username } }"
          >
            <profile-image :user="teamMember.user" />
          </router-link>
        </div>

        <div>
          <div>
            <router-link
              :to="{ name: 'user_profile', params: { username: teamMember.user.username } }"
            >
              {{ teamMember.user.first_name }} {{ teamMember.user.last_name }}
            </router-link>
          </div>

          <template v-if="activeTab === 'members-current'">
            Role: {{ roleMap[teamMember.role] }}
          </template>

          <div class="joined-and-invited">
            <template v-if="activeTab === 'members-current' && teamMember.joined">
              Joined on {{ timestampDisplay(teamMember.joined) }}
            </template>

            <div
              v-if="
                teamMember.user.username !== teamMember.inviter.username
                  && !(
                    activeTab === 'members-invitations_sent'
                    && teamUserRoleAndAccess.role === 'regular'
                  )
              "
            >
              Invited by
              <router-link
                :to="{ name: 'user_profile', params: { username: teamMember.inviter.username } }"
              >
                {{ teamMember.inviter.first_name }} {{ teamMember.inviter.last_name }}
              </router-link>
            </div>
          </div>
        </div>

        <div
          v-if="
            teamMember.user.username !== userData.username
              && (
                activeTab === 'members-invitations_sent'
                || teamUserRoleAndAccess.role === 'admin'
                || (
                  teamUserRoleAndAccess.role === 'moderator'
                  && teamMember.role === 'regular'
                )
              )
          "
          class="buttons"
        >
          <template v-if="activeTab === 'members-current'">
            <button
              v-if="teamUserRoleAndAccess.role === 'admin'"
              type="button"
              class="btn btn-outline-primary btn-xs"
              :disabled="
                changeRoleInProgress.includes(teamMember.user.username)
                  || removeTeamMemberInProgress.includes(teamMember.user.username)
              "
              @click="roleModalTeamMember = teamMember"
            >
              <template v-if="changeRoleInProgress.includes(teamMember.user.username)">
                Changing Role
                <spinner color="var(--blue)" />
              </template>

              <template v-else>
                Change Role
              </template>
            </button>

            <button
              v-if="teamUserRoleAndAccess.role === 'admin' || teamMember.role === 'regular'"
              type="button"
              class="btn btn-outline-danger btn-xs"
              :disabled="
                changeRoleInProgress.includes(teamMember.user.username)
                  || removeTeamMemberInProgress.includes(teamMember.user.username)
              "
              @click="removeTeamMember(teamMember)"
            >
              <template v-if="removeTeamMemberInProgress.includes(teamMember.user.username)">
                Removing
                <spinner color="var(--red)" />
              </template>

              <template v-else>
                Remove
              </template>
            </button>
          </template>

          <template v-else-if="activeTab === 'members-join_requests'">
            <button
              type="button"
              class="btn btn-outline-primary btn-xs"
              :disabled="
                approveJoinRequestInProgress.includes(teamMember.user.username)
                  || denyJoinRequestInProgress.includes(teamMember.user.username)
              "
              @click="approveJoinRequest(teamMember)"
            >
              <template v-if="approveJoinRequestInProgress.includes(teamMember.user.username)">
                Approving
                <spinner color="var(--blue)" />
              </template>

              <template v-else>
                Approve
              </template>
            </button>

            <button
              type="button"
              class="btn btn-outline-danger btn-xs"
              :disabled="
                approveJoinRequestInProgress.includes(teamMember.user.username)
                  || denyJoinRequestInProgress.includes(teamMember.user.username)
              "
              @click="denyJoinRequest(teamMember)"
            >
              <template v-if="denyJoinRequestInProgress.includes(teamMember.user.username)">
                Denying
                <spinner color="var(--red)" />
              </template>

              <template v-else>
                Deny
              </template>
            </button>
          </template>

          <button
            v-else-if="activeTab === 'members-invitations_sent'"
            type="button"
            class="btn btn-outline-danger btn-xs"
            :disabled="cancelInvitationInProgress.includes(teamMember.user.username)"
            @click="cancelInvitation(teamMember)"
          >
            <template v-if="cancelInvitationInProgress.includes(teamMember.user.username)">
              Canceling
              <spinner color="var(--red)" />
            </template>

            <template v-else>
              Cancel
            </template>
          </button>
        </div>
      </div>

      <div class="load-more-content">
        <button
          v-show="moreTeamMembersStatus === 'idle' && teamMembers.next"
          ref="loadMoreTeamMembersButton"
          type="button"
          class="btn btn-outline-primary load-more-team-members-button"
          @click="loadMoreTeamMembers"
        >
          Load More Team Members
        </button>

        <spinner
          v-if="moreTeamMembersStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="moreTeamMembersStatus === 'error'">
          <alert variant="danger">
            An error occurred while trying to load more team members. Please
            check your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMoreTeamMembers"
          >
            Try Again
          </button>
        </template>
      </div>
    </template>

    <div
      v-else
      class="text-center"
    >
      Nothing to see here.
    </div>
  </div>

  <modal
    ref="roleModal"
    :show="roleModalTeamMember !== null"
    class="role-modal"
    @modalClosed="roleModalTeamMember = null"
  >
    <template #header>
      <h2 v-if="roleModalTeamMember !== null">
        Choose
        {{ roleModalTeamMember.user.first_name }} {{ roleModalTeamMember.user.last_name }}'s Role
      </h2>
    </template>

    <div
      v-if="roleModalTeamMember !== null"
      class="horizontal-padding"
    >
      <button
        type="button"
        class="btn"
        :class="{
          'btn-primary': roleModalTeamMember.role === 'admin',
          'btn-outline-primary': roleModalTeamMember.role !== 'admin',
        }"
        aria-describedby="role-description-admin"
        :aria-pressed="roleModalTeamMember.role === 'admin'"
        @click="changeRole('admin')"
      >
        Administrator
      </button>

      <div
        id="role-description-admin"
        class="role-description"
      >
        Administrators have full control of the team.
      </div>

      <button
        type="button"
        class="btn"
        :class="{
          'btn-primary': roleModalTeamMember.role === 'moderator',
          'btn-outline-primary': roleModalTeamMember.role !== 'moderator',
        }"
        aria-describedby="role-description-moderator"
        :aria-pressed="roleModalTeamMember.role === 'moderator'"
        @click="changeRole('moderator')"
      >
        Moderator
      </button>

      <div
        id="role-description-moderator"
        class="role-description"
      >
        Moderators have the ability to:

        <ul>
          <li>Delete posts and comments made by other users</li>
          <li>Accept or deny join requests</li>
          <li>Remove members from the team</li>
        </ul>
      </div>

      <button
        type="button"
        class="btn"
        :class="{
          'btn-primary': roleModalTeamMember.role === 'regular',
          'btn-outline-primary': roleModalTeamMember.role !== 'regular',
        }"
        :aria-pressed="roleModalTeamMember.role === 'regular'"
        @click="changeRole('regular')"
      >
        Member
      </button>
    </div>
  </modal>
</template>

<script lang="ts">
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { MembershipInterface, UserRoleAndAccessInterface } from '@/interfaces/teams';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
      required: true,
    },
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: true,
    },
  },
  emits: [
    'roleChanged',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teamMembers: {} as {
      next: string | null,
      previous: string | null,
      results: Array<MembershipInterface>,
    },
    moreTeamMembersStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    roleModalTeamMember: null as MembershipInterface | null,
    roleMap: {
      regular: 'Member',
      moderator: 'Moderator',
      admin: 'Administrator',
    },
    approveJoinRequestInProgress: [] as Array<string>,
    denyJoinRequestInProgress: [] as Array<string>,
    cancelInvitationInProgress: [] as Array<string>,
    changeRoleInProgress: [] as Array<string>,
    removeTeamMemberInProgress: [] as Array<string>,
  }),
  created() {
    this.loadTeamMembers();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    approveJoinRequest(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to approve ${fullName}'s request to join the team?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.approveJoinRequestInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/join_requests/`,
            method: 'POST',
            json: { action: 'approve', username: teamMember.user.username },
          });

          this.approveJoinRequestInProgress = this.approveJoinRequestInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Approve ${fullName}'s Request to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    cancelInvitation(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to cancel the invitation for ${fullName} to join the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel It',
        cancelButtonText: "No, Don't Cancel It",
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.cancelInvitationInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/invitations_sent/`,
            method: 'POST',
            json: { username: teamMember.user.username },
          });

          this.cancelInvitationInProgress = this.cancelInvitationInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Cancel the Invitation for ${fullName} to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    changeRole(role: 'regular' | 'moderator' | 'admin') {
      const roleModal = this.$refs.roleModal as InstanceType<typeof Modal>;
      const teamMember = this.roleModalTeamMember as MembershipInterface;

      if (teamMember.role === role) {
        roleModal.closeModal();
        return;
      }

      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to change ${fullName}'s role to "${this.roleMap[role]}"?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Change It',
        target: roleModal.$el,
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.changeRoleInProgress.push(teamMember.user.username);

          roleModal.closeModal();

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/members/`,
            method: 'POST',
            json: { action: 'change_role', role, username: teamMember.user.username },
          });

          this.changeRoleInProgress = this.changeRoleInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            setTimeout(() => {
              this.$emit('roleChanged');
            }, 200);
          } else {
            const title = `Failed to Change ${fullName}'s Role`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    denyJoinRequest(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to deny ${fullName}'s request to join the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Deny It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.denyJoinRequestInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/join_requests/`,
            method: 'POST',
            json: { action: 'deny', username: teamMember.user.username },
          });

          this.denyJoinRequestInProgress = this.denyJoinRequestInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Deny ${fullName}'s Request to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    async loadTeamMembers() {
      this.status = 'loading';

      let url = `teams/${this.teamSlug}/`;

      if (this.activeTab === 'members-current') {
        url += 'members/';
      } else if (this.activeTab === 'members-join_requests') {
        url += 'join_requests/';
      } else if (this.activeTab === 'members-invitations_sent') {
        url += 'invitations_sent/';
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.teamMembers = responseData.body;
        this.status = 'loaded';

        if (this.teamMembers.next) {
          nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreTeamMembers();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreTeamMembersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreTeamMembers() {
      this.moreTeamMembersStatus = 'loading';

      const responseData = await this.api({
        url: this.teamMembers.next as string,
      });

      if (responseData.status === 200) {
        this.teamMembers.next = responseData.body.next;
        this.teamMembers.previous = responseData.body.previous;
        this.teamMembers.results = this.teamMembers.results.concat(responseData.body.results);
        this.moreTeamMembersStatus = 'idle';
      } else {
        this.moreTeamMembersStatus = 'error';
      }
    },
    removeTeamMember(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to remove ${fullName} from the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove Them',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.removeTeamMemberInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/members/`,
            method: 'POST',
            json: { action: 'remove', username: teamMember.user.username },
          });

          this.removeTeamMemberInProgress = this.removeTeamMemberInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Remove ${fullName} from the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  nav ul {
    padding-left: 0;
    text-align: center;

    li {
      display: inline-block;

      + li {
        margin-left: 0.5rem;
      }
    }
  }

  .team-member {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr) 6.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-wrap: break-word;

    + .team-member {
      border-top: 1px solid var(--gray-darkest);
    }
  }

  .joined-and-invited {
    margin-top: 0.5rem;
    font-size: 0.8em;
    color: var(--gray-light)
  }

  .buttons {
    .btn {
      display: block;
      margin: 0 auto;
      width: 100%;
    }

    .btn + .btn {
      margin-top: 0.5rem;
    }
  }

  .load-more-content {
    margin-top: 1rem;
  }

  .load-more-team-members-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .role-modal .btn {
    display: block;
    width: 100%;
  }

  .role-description {
    margin: 0.5rem 0 2rem;
    font-size: 0.8em;
  }
</style>
