
import { defineComponent, nextTick } from 'vue';
// @ts-expect-error Could not find a declaration file for module 'vue-cropperjs'.
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import TransitionSlide from '@/components/TransitionSlide.vue';
import GameField from '@/components/games/GameField.vue';
import PlatformsFieldset from '@/components/games/PlatformsFieldset.vue';
import { GameInterface } from '@/interfaces/games';
import dataURItoFile from '@/methods/data_uri_to_file';

export default defineComponent({
  components: {
    VueCropper,
    GameField,
    PlatformsFieldset,
    TransitionSlide,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
      required: true,
    },
  },
  emits: [
    'basicSettingsSaved',
    'coverImageSaved',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    basicFormFields: {
      name: '',
      slug: '',
      visibility: '',
      invite_only: false,
      description: '',
    },
    basicFormErrors: {} as Record<string, Array<string>>,
    basicFormSubmitting: false,
    basicFormSuccess: false,
    selectedGame: {} as {
      nameAndYear: string;
      slug: string;
      coverImageId: string;
      platforms: Array<string>;
    } | Record<string, never>,
    selectedPlatforms: [] as Array<string>,
    initialCoverImage: null as null | string,
    coverImageAction: 'do_nothing' as 'do_nothing' | 'add' | 'change' | 'delete',
    coverImageNonCroppedLoading: false,
    coverImageNonCropped: '',
    coverImageFormErrors: {} as Record<string, Array<string>>,
    coverImageFormSubmitting: false,
    coverImageFormSuccess: false,
    deletingTeam: false,
    deleteTeamError: false,
  }),
  watch: {
    basicFormErrors() {
      if (!this.isObjectEmpty(this.basicFormErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
    coverImageFormErrors() {
      if (!this.isObjectEmpty(this.coverImageFormErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    coverImageCropperReady() {
      this.coverImageNonCroppedLoading = false;
    },
    async deleteTeam() {
      this.deleteTeamError = false;
      this.deletingTeam = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/`,
        method: 'DELETE',
      });

      if (responseData.status === 204) {
        this.$router.push({ name: 'my_teams' });
      } else {
        this.deletingTeam = false;
        this.deleteTeamError = true;
      }
    },
    async loadData() {
      this.status = 'loading';

      let url = `teams/${this.teamSlug}/`;

      if (this.activeTab === 'settings-basic') {
        url += 'team_settings/';
      } else if (this.activeTab === 'settings-cover_image') {
        url += 'cover_image/';
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        if (this.activeTab === 'settings-basic') {
          const { body } = responseData;

          this.selectedGame = {
            nameAndYear: body.game_data.name_and_year,
            slug: body.game_data.slug,
            coverImageId: body.game_data.cover_image_id,
            platforms: body.game_data.platforms,
          };

          delete body.game_data;

          this.selectedPlatforms = body.initial_platforms;
          delete body.initial_platforms;

          this.basicFormFields = body;
        } else if (this.activeTab === 'settings-cover_image') {
          this.initialCoverImage = responseData.body.cover_image;
        }

        this.status = 'loaded';
      } else if (responseData.status === 404) {
        this.status = 'not_found';
      } else {
        this.status = 'error';
      }
    },
    selectedGameChanged(newGame: GameInterface | Record<string, never>) {
      if (newGame.slug === undefined) {
        this.selectedGame = {};
      } else {
        this.selectedGame = {
          nameAndYear: `${newGame.name} (${newGame.first_release_date.substring(0, 4)})`,
          slug: newGame.slug,
          coverImageId: newGame.cover_image_id,
          platforms: newGame.platforms as Array<string>,
        };
      }

      this.selectedPlatforms = [];
    },
    selectedPlatformsChanged(newPlatforms: Array<string>) {
      this.selectedPlatforms = newPlatforms;
    },
    setCoverImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.coverImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.coverImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.coverImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.coverImageNonCropped = '';
      }
    },
    async submitBasicForm() {
      this.basicFormErrors = {};
      this.basicFormSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.basicFormFields));

      json.game = this.selectedGame.slug === undefined ? '' : this.selectedGame.slug;

      if (this.selectedPlatforms.length) {
        json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/team_settings/`,
        method: 'POST',
        json,
      });

      this.basicFormSubmitting = false;

      if (responseData.status === 200) {
        this.$emit('basicSettingsSaved', responseData.body);
        this.basicFormSuccess = true;
      } else if (responseData.status === 400) {
        this.basicFormErrors = responseData.body;
      } else {
        this.basicFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitCoverImageForm() {
      this.coverImageFormErrors = {};
      this.coverImageFormSubmitting = true;

      const formErrors = {} as Record<string, Array<string>>;

      if (this.coverImageNonCropped === '') {
        if (this.coverImageAction === 'add') {
          formErrors.cover_image = ['Please choose a cover image.'];
        } else if (this.coverImageAction === 'change') {
          formErrors.cover_image = ['Please choose a new cover image.'];
        }
      }

      if (!this.isObjectEmpty(formErrors)) {
        this.coverImageFormErrors = formErrors;
        this.coverImageFormSubmitting = false;
        return;
      }

      const body = new FormData();

      if (['add', 'change'].includes(this.coverImageAction)) {
        const imageType = this.coverImageNonCropped.substring(
          5,
          this.coverImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'cover_image',
          dataURItoFile(
            (this.$refs.coverImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 1920,
              height: 400,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.coverImageAction === 'delete') {
        body.append('delete_cover_image', 'true');
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/cover_image/`,
        method: 'POST',
        body,
      });

      this.coverImageFormSubmitting = false;

      if (responseData.status === 200) {
        this.$emit('coverImageSaved', responseData.body.cover_image);
        this.coverImageFormSuccess = true;
      } else if (responseData.status === 400) {
        this.coverImageFormErrors = responseData.body;
      } else {
        this.coverImageFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
