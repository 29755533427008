<template>
  <nav aria-label="Team Settings">
    <ul>
      <li>
        <router-link
          :to="{ name: 'team_settings', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Basic Settings
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'team_cover_image', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Cover Image
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'delete_team', params: { teamSlug } }"
          class="btn btn-xs"
          exact-active-class="btn-primary"
        >
          Delete Team
        </router-link>
      </li>
    </ul>
  </nav>

  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the team data. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadData"
    >
      Try Again
    </button>
  </template>

  <alert
    v-else-if="status === 'not_found'"
    variant="danger"
  >
    This team no longer exists.
  </alert>

  <template v-else-if="status === 'loaded'">
    <template v-if="activeTab === 'settings-basic'">
      <alert
        v-if="basicFormSuccess"
        variant="success"
      >
        Your changes have been saved.
      </alert>

      <template v-else>
        <alert
          v-if="basicFormErrors.non_field_errors !== undefined"
          variant="danger"
          class="js-form-error"
          dismissible
          @dismissed="basicFormErrors = {}"
        >
          {{ basicFormErrors.non_field_errors[0] }}
        </alert>

        <form @submit.prevent="submitBasicForm">
          <div class="form-group">
            <label for="name">Team name</label>
            <input
              id="name"
              v-model="basicFormFields.name"
              :class="{ 'is-invalid': basicFormErrors.name !== undefined }"
              type="text"
              maxlength="75"
              required
            >
            <div
              v-if="basicFormErrors.name !== undefined"
              class="invalid-feedback js-form-error"
            >
              {{ basicFormErrors.name[0] }}
            </div>
          </div>

          <div class="form-group">
            <label for="slug">Web address</label>
            <input
              id="slug"
              v-model="basicFormFields.slug"
              :class="{ 'is-invalid': basicFormErrors.slug !== undefined }"
              type="text"
              maxlength="75"
              required
              aria-describedby="slug_help"
            >
            <small
              id="slug_help"
              class="form-text"
            >
              theplayersarena.com/team/{{ basicFormFields.slug }}
            </small>
            <div
              v-if="basicFormErrors.slug !== undefined"
              class="invalid-feedback js-form-error"
            >
              {{ basicFormErrors.slug[0] }}
            </div>
          </div>

          <game-field
            :selected-game="selectedGame"
            :error-message="basicFormErrors.game === undefined ? '' : basicFormErrors.game[0]"
            @changed="selectedGameChanged"
          />

          <platforms-fieldset
            v-if="selectedGame.slug !== undefined && selectedGame.platforms.length > 1"
            :available-platforms="selectedGame.platforms"
            :initial-platforms="selectedPlatforms"
            :error-message="
              basicFormErrors.platforms === undefined ? '' : basicFormErrors.platforms[0]
            "
            @changed="selectedPlatformsChanged"
          />

          <div class="form-group">
            <fieldset>
              <legend>Visibility</legend>

              <div class="form-check">
                <input
                  id="visibility_public"
                  v-model="basicFormFields.visibility"
                  type="radio"
                  name="visibility"
                  value="public"
                  class="form-check-input"
                  aria-describedby="visibility_public_description"
                >
                <label
                  for="visibility_public"
                  class="form-check-label"
                >
                  Public
                </label>

                <small id="visibility_public_description">
                  Everybody can see this team's posts and comments
                </small>
              </div>

              <div class="form-check">
                <input
                  id="visibility_listed"
                  v-model="basicFormFields.visibility"
                  type="radio"
                  name="visibility"
                  value="listed"
                  class="form-check-input"
                  aria-describedby="visibility_listed_description"
                >
                <label
                  for="visibility_listed"
                  class="form-check-label"
                >
                  Listed
                </label>

                <small id="visibility_listed_description">
                  This team is discoverable (e.g. via search), but only members of
                  this team can see its posts and comments
                </small>
              </div>

              <div class="form-check">
                <input
                  id="visibility_unlisted"
                  v-model="basicFormFields.visibility"
                  type="radio"
                  name="visibility"
                  value="unlisted"
                  class="form-check-input"
                  aria-describedby="visibility_unlisted_description"
                >
                <label
                  for="visibility_unlisted"
                  class="form-check-label"
                >
                  Unlisted
                </label>

                <small id="visibility_unlisted_description">
                  This team is not discoverable, members must be invited to join
                  the team, and only members of this team can see its posts and
                  comments
                </small>
              </div>
            </fieldset>
          </div>

          <transition-slide mode="out-in">
            <div
              v-if="basicFormFields.visibility !== 'unlisted'"
              class="form-group"
            >
              <div class="form-check">
                <input
                  id="invite_only"
                  v-model="basicFormFields.invite_only"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  for="invite_only"
                  class="form-check-label"
                >
                  This team should be invite only
                </label>
              </div>
            </div>
          </transition-slide>

          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              id="description"
              v-model="basicFormFields.description"
              :class="{ 'is-invalid': basicFormErrors.description !== undefined }"
              required
            />
            <div
              v-if="basicFormErrors.description !== undefined"
              class="invalid-feedback js-form-error"
            >
              {{ basicFormErrors.description[0] }}
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            :disabled="basicFormSubmitting"
          >
            <template v-if="basicFormSubmitting">
              Saving
              <spinner />
            </template>

            <template v-else>
              Save
            </template>
          </button>
        </form>
      </template>
    </template>

    <template v-if="activeTab === 'settings-cover_image'">
      <alert
        v-if="coverImageFormSuccess"
        variant="success"
      >
        Your changes have been saved.
      </alert>

      <template v-else>
        <alert
          v-if="coverImageFormErrors.non_field_errors !== undefined"
          variant="danger"
          class="js-form-error"
          dismissible
          @dismissed="coverImageFormErrors = {}"
        >
          {{ coverImageFormErrors.non_field_errors[0] }}
        </alert>

        <img
          v-if="initialCoverImage"
          :src="initialCoverImage"
          alt
          class="img-fluid current-cover-image"
        >

        <form
          class="text-center"
          @submit.prevent="submitCoverImageForm"
        >
          <transition-slide mode="out-in">
            <div v-if="coverImageAction === 'do_nothing'">
              <template v-if="initialCoverImage === null">
                <p>The team does not currently have a cover image.</p>

                <button
                  type="button"
                  class="btn btn-primary"
                  aria-label="Add Cover Image"
                  @click="coverImageAction = 'add'"
                >
                  Add a Cover Image
                </button>
              </template>

              <template v-else>
                <button
                  type="button"
                  class="btn btn-primary"
                  aria-label="Change Cover Image"
                  @click="coverImageAction = 'change'"
                >
                  Change
                </button>

                <button
                  type="button"
                  class="btn btn-danger"
                  aria-label="Delete Cover Image"
                  @click="coverImageAction = 'delete'"
                >
                  Delete
                </button>
              </template>
            </div>

            <div v-else-if="['add', 'change'].includes(coverImageAction)">
              <div class="form-group">
                <label for="new_cover_image">New cover image</label>
                <input
                  id="new_cover_image"
                  :class="{ 'is-invalid': coverImageFormErrors.cover_image !== undefined }"
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  @change="setCoverImageNonCropped"
                >
                <div
                  v-if="coverImageFormErrors.cover_image !== undefined"
                  class="invalid-feedback js-form-error"
                >
                  {{ coverImageFormErrors.cover_image[0] }}
                </div>
              </div>

              <div
                v-if="coverImageNonCroppedLoading || coverImageNonCropped"
                class="cropper-message"
              >
                <template v-if="coverImageNonCroppedLoading">
                  Loading...
                </template>

                <template v-else>
                  Use the blue outline below to specify how you would like the team
                  cover image to be cropped. When you're ready, click the "Save"
                  button below.
                </template>
              </div>

              <vue-cropper
                v-if="coverImageNonCropped"
                ref="coverImageCropper"
                :view-mode="2"
                :aspect-ratio="4.8"
                :guides="false"
                :background="false"
                :auto-crop-area="0.5"
                :zoomable="false"
                :src="coverImageNonCropped"
                :ready="coverImageCropperReady"
              />

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="coverImageFormSubmitting"
              >
                <template v-if="coverImageFormSubmitting">
                  Saving
                  <spinner />
                </template>

                <template v-else>
                  Save
                </template>
              </button>

              <button
                type="button"
                class="btn btn-light"
                :disabled="coverImageFormSubmitting"
                @click="coverImageAction = 'do_nothing'"
              >
                Cancel
              </button>
            </div>

            <div v-else-if="coverImageAction === 'delete'">
              <alert variant="danger">
                The team cover image will be deleted.
              </alert>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="coverImageFormSubmitting"
              >
                <template v-if="coverImageFormSubmitting">
                  Saving
                  <spinner />
                </template>

                <template v-else>
                  Save
                </template>
              </button>

              <button
                type="button"
                class="btn btn-light"
                :disabled="coverImageFormSubmitting"
                @click="coverImageAction = 'do_nothing'"
              >
                Cancel
              </button>
            </div>
          </transition-slide>
        </form>
      </template>
    </template>

    <template v-if="activeTab === 'settings-delete'">
      <alert
        v-if="deleteTeamError"
        variant="danger"
        dismissible
        @dismissed="deleteTeamError = false"
      >
        An error occurred deleting the team. Please check your connection and
        try again.
      </alert>

      <div class="text-center">
        <p>Are you sure you want to delete this team?</p>

        <button
          type="button"
          class="btn btn-danger"
          :disabled="deletingTeam"
          @click="deleteTeam"
        >
          <template v-if="deletingTeam">
            Deleting This Team
            <spinner />
          </template>

          <template v-else>
            Yes, Delete This Team
          </template>
        </button>
      </div>
    </template>
  </template>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
// @ts-expect-error Could not find a declaration file for module 'vue-cropperjs'.
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import TransitionSlide from '@/components/TransitionSlide.vue';
import GameField from '@/components/games/GameField.vue';
import PlatformsFieldset from '@/components/games/PlatformsFieldset.vue';
import { GameInterface } from '@/interfaces/games';
import dataURItoFile from '@/methods/data_uri_to_file';

export default defineComponent({
  components: {
    VueCropper,
    GameField,
    PlatformsFieldset,
    TransitionSlide,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
      required: true,
    },
  },
  emits: [
    'basicSettingsSaved',
    'coverImageSaved',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    basicFormFields: {
      name: '',
      slug: '',
      visibility: '',
      invite_only: false,
      description: '',
    },
    basicFormErrors: {} as Record<string, Array<string>>,
    basicFormSubmitting: false,
    basicFormSuccess: false,
    selectedGame: {} as {
      nameAndYear: string;
      slug: string;
      coverImageId: string;
      platforms: Array<string>;
    } | Record<string, never>,
    selectedPlatforms: [] as Array<string>,
    initialCoverImage: null as null | string,
    coverImageAction: 'do_nothing' as 'do_nothing' | 'add' | 'change' | 'delete',
    coverImageNonCroppedLoading: false,
    coverImageNonCropped: '',
    coverImageFormErrors: {} as Record<string, Array<string>>,
    coverImageFormSubmitting: false,
    coverImageFormSuccess: false,
    deletingTeam: false,
    deleteTeamError: false,
  }),
  watch: {
    basicFormErrors() {
      if (!this.isObjectEmpty(this.basicFormErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
    coverImageFormErrors() {
      if (!this.isObjectEmpty(this.coverImageFormErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    coverImageCropperReady() {
      this.coverImageNonCroppedLoading = false;
    },
    async deleteTeam() {
      this.deleteTeamError = false;
      this.deletingTeam = true;

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/`,
        method: 'DELETE',
      });

      if (responseData.status === 204) {
        this.$router.push({ name: 'my_teams' });
      } else {
        this.deletingTeam = false;
        this.deleteTeamError = true;
      }
    },
    async loadData() {
      this.status = 'loading';

      let url = `teams/${this.teamSlug}/`;

      if (this.activeTab === 'settings-basic') {
        url += 'team_settings/';
      } else if (this.activeTab === 'settings-cover_image') {
        url += 'cover_image/';
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        if (this.activeTab === 'settings-basic') {
          const { body } = responseData;

          this.selectedGame = {
            nameAndYear: body.game_data.name_and_year,
            slug: body.game_data.slug,
            coverImageId: body.game_data.cover_image_id,
            platforms: body.game_data.platforms,
          };

          delete body.game_data;

          this.selectedPlatforms = body.initial_platforms;
          delete body.initial_platforms;

          this.basicFormFields = body;
        } else if (this.activeTab === 'settings-cover_image') {
          this.initialCoverImage = responseData.body.cover_image;
        }

        this.status = 'loaded';
      } else if (responseData.status === 404) {
        this.status = 'not_found';
      } else {
        this.status = 'error';
      }
    },
    selectedGameChanged(newGame: GameInterface | Record<string, never>) {
      if (newGame.slug === undefined) {
        this.selectedGame = {};
      } else {
        this.selectedGame = {
          nameAndYear: `${newGame.name} (${newGame.first_release_date.substring(0, 4)})`,
          slug: newGame.slug,
          coverImageId: newGame.cover_image_id,
          platforms: newGame.platforms as Array<string>,
        };
      }

      this.selectedPlatforms = [];
    },
    selectedPlatformsChanged(newPlatforms: Array<string>) {
      this.selectedPlatforms = newPlatforms;
    },
    setCoverImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.coverImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.coverImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.coverImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.coverImageNonCropped = '';
      }
    },
    async submitBasicForm() {
      this.basicFormErrors = {};
      this.basicFormSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.basicFormFields));

      json.game = this.selectedGame.slug === undefined ? '' : this.selectedGame.slug;

      if (this.selectedPlatforms.length) {
        json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/team_settings/`,
        method: 'POST',
        json,
      });

      this.basicFormSubmitting = false;

      if (responseData.status === 200) {
        this.$emit('basicSettingsSaved', responseData.body);
        this.basicFormSuccess = true;
      } else if (responseData.status === 400) {
        this.basicFormErrors = responseData.body;
      } else {
        this.basicFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitCoverImageForm() {
      this.coverImageFormErrors = {};
      this.coverImageFormSubmitting = true;

      const formErrors = {} as Record<string, Array<string>>;

      if (this.coverImageNonCropped === '') {
        if (this.coverImageAction === 'add') {
          formErrors.cover_image = ['Please choose a cover image.'];
        } else if (this.coverImageAction === 'change') {
          formErrors.cover_image = ['Please choose a new cover image.'];
        }
      }

      if (!this.isObjectEmpty(formErrors)) {
        this.coverImageFormErrors = formErrors;
        this.coverImageFormSubmitting = false;
        return;
      }

      const body = new FormData();

      if (['add', 'change'].includes(this.coverImageAction)) {
        const imageType = this.coverImageNonCropped.substring(
          5,
          this.coverImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'cover_image',
          dataURItoFile(
            (this.$refs.coverImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 1920,
              height: 400,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.coverImageAction === 'delete') {
        body.append('delete_cover_image', 'true');
      }

      const responseData = await this.api({
        url: `teams/${this.teamSlug}/cover_image/`,
        method: 'POST',
        body,
      });

      this.coverImageFormSubmitting = false;

      if (responseData.status === 200) {
        this.$emit('coverImageSaved', responseData.body.cover_image);
        this.coverImageFormSuccess = true;
      } else if (responseData.status === 400) {
        this.coverImageFormErrors = responseData.body;
      } else {
        this.coverImageFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  nav ul {
    padding-left: 0;
    text-align: center;

    li {
      display: inline-block;

      + li {
        margin-left: 0.5rem;
      }
    }
  }

  .current-cover-image,
  .cropper-message,
  :deep(.cropper-container) {
    margin-bottom: 1rem;
  }

  .current-cover-image {
    display: block;
  }

  input[type="file"] {
    margin: 0 auto;
  }
</style>
