
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { MembershipInterface, UserRoleAndAccessInterface } from '@/interfaces/teams';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
      required: true,
    },
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: true,
    },
  },
  emits: [
    'roleChanged',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teamMembers: {} as {
      next: string | null,
      previous: string | null,
      results: Array<MembershipInterface>,
    },
    moreTeamMembersStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    roleModalTeamMember: null as MembershipInterface | null,
    roleMap: {
      regular: 'Member',
      moderator: 'Moderator',
      admin: 'Administrator',
    },
    approveJoinRequestInProgress: [] as Array<string>,
    denyJoinRequestInProgress: [] as Array<string>,
    cancelInvitationInProgress: [] as Array<string>,
    changeRoleInProgress: [] as Array<string>,
    removeTeamMemberInProgress: [] as Array<string>,
  }),
  created() {
    this.loadTeamMembers();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    approveJoinRequest(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to approve ${fullName}'s request to join the team?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.approveJoinRequestInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/join_requests/`,
            method: 'POST',
            json: { action: 'approve', username: teamMember.user.username },
          });

          this.approveJoinRequestInProgress = this.approveJoinRequestInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Approve ${fullName}'s Request to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    cancelInvitation(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to cancel the invitation for ${fullName} to join the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel It',
        cancelButtonText: "No, Don't Cancel It",
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.cancelInvitationInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/invitations_sent/`,
            method: 'POST',
            json: { username: teamMember.user.username },
          });

          this.cancelInvitationInProgress = this.cancelInvitationInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Cancel the Invitation for ${fullName} to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    changeRole(role: 'regular' | 'moderator' | 'admin') {
      const roleModal = this.$refs.roleModal as InstanceType<typeof Modal>;
      const teamMember = this.roleModalTeamMember as MembershipInterface;

      if (teamMember.role === role) {
        roleModal.closeModal();
        return;
      }

      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to change ${fullName}'s role to "${this.roleMap[role]}"?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Change It',
        target: roleModal.$el,
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.changeRoleInProgress.push(teamMember.user.username);

          roleModal.closeModal();

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/members/`,
            method: 'POST',
            json: { action: 'change_role', role, username: teamMember.user.username },
          });

          this.changeRoleInProgress = this.changeRoleInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            setTimeout(() => {
              this.$emit('roleChanged');
            }, 200);
          } else {
            const title = `Failed to Change ${fullName}'s Role`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    denyJoinRequest(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to deny ${fullName}'s request to join the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Deny It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.denyJoinRequestInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/join_requests/`,
            method: 'POST',
            json: { action: 'deny', username: teamMember.user.username },
          });

          this.denyJoinRequestInProgress = this.denyJoinRequestInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Deny ${fullName}'s Request to Join the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    async loadTeamMembers() {
      this.status = 'loading';

      let url = `teams/${this.teamSlug}/`;

      if (this.activeTab === 'members-current') {
        url += 'members/';
      } else if (this.activeTab === 'members-join_requests') {
        url += 'join_requests/';
      } else if (this.activeTab === 'members-invitations_sent') {
        url += 'invitations_sent/';
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.teamMembers = responseData.body;
        this.status = 'loaded';

        if (this.teamMembers.next) {
          nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreTeamMembers();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreTeamMembersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreTeamMembers() {
      this.moreTeamMembersStatus = 'loading';

      const responseData = await this.api({
        url: this.teamMembers.next as string,
      });

      if (responseData.status === 200) {
        this.teamMembers.next = responseData.body.next;
        this.teamMembers.previous = responseData.body.previous;
        this.teamMembers.results = this.teamMembers.results.concat(responseData.body.results);
        this.moreTeamMembersStatus = 'idle';
      } else {
        this.moreTeamMembersStatus = 'error';
      }
    },
    removeTeamMember(teamMember: MembershipInterface) {
      const fullName = `${teamMember.user.first_name} ${teamMember.user.last_name}`;

      this.$swal.fire({
        title: 'Are You Sure?',
        text: `Do you really want to remove ${fullName} from the team?`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove Them',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.removeTeamMemberInProgress.push(teamMember.user.username);

          const responseData = await this.api({
            url: `teams/${this.teamSlug}/members/`,
            method: 'POST',
            json: { action: 'remove', username: teamMember.user.username },
          });

          this.removeTeamMemberInProgress = this.removeTeamMemberInProgress.filter(
            (username) => username !== teamMember.user.username,
          );

          if (responseData.status === 204) {
            this.teamMembers.results = this.teamMembers.results.filter(
              (tm) => tm.user.username !== teamMember.user.username,
            );
          } else {
            const title = `Failed to Remove ${fullName} from the Team`;
            let text;

            if (responseData.status === 400) {
              text = responseData.body;
            } else if (responseData.status === 404) {
              text = 'This team no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
  },
});
